<template>
    <v-footer
        tile
        flat
        height="90"
        class="font-weight-medium shadow-md"
        :color="$vuetify.theme.dark ? 'dark' : 'white'"
        :dark="$vuetify.theme.dark"
        :inset="true"
    >
        <v-col
            class="text-left py-0"
            cols="12"
        >
            <div class="d-flex flex-row justify-space-between align-center">
                <v-img
                    src="@/assets/img/logo-conecta.svg"
                    alt=""
                    max-width="120px"
                    max-height="84px"
                    class="flex-shrink-1"
                >
                    <!--                    <language-selector />-->
                </v-img>

                <div class="pl-2 flex-shrink-1 text-small">
                    <div
                        class="flex-grow-1 mb-3"
                        style="min-width: 40px"
                    >
                        <p class="ma-0 text-right text-gray-700">
                            {{ getProgresssData.progressbarText }}
                        </p>
                        <v-progress-linear
                            :color="getProgresssData.progressColor"
                            :value="getProgresssData.progressValue"
                            rounded
                        />
                    </div>

                    <span class="text--disabled">
                        3Weeks Consulting © {{ new Date().getFullYear() }}
                    </span>
                </div>
            </div>
        </v-col>
    </v-footer>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'Footer',
    /* components: {
        LanguageSelector: () => import('../../components/common/LanguageSelector.vue')
    }, */
    computed: {
        ...mapGetters('cache', ['getPostsOrdered']),
        ...mapGetters(['getIndexShowPost']),
        getProgresssData() {
            let max = this.getPostsOrdered.length;
            if (max) max -= 2;

            const count = this.getIndexShowPost + 1 > max ? max : this.getIndexShowPost + 1;

            const progressValue = Math.ceil(max > 0 ? count / max * 100 : 0);

            let progressColor = 'danger';
            if (progressValue >= 99) progressColor = 'secondary';
            else if (progressValue >= 85) progressColor = 'primary';

            let progressbarText = `${count} - ${max} `;
            progressbarText += this.$t('home.unlockedPercent', { percent: progressValue });

            return { progressValue, progressColor, progressbarText };
        }
    }
}
</script>
